import React, { useState } from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  width: 100%;
  // padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // margin: 200px 0 0 0;
  height: 560px;

  @media (max-width: 1200px) {
    height: 400px;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/video-background2.png');
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  z-index: 2;
`;

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: clamp(48px, 10vw, 64px);
  font-weight: 900;
  font-style: italic;
  color: white;
  line-height: 1.1;
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PlayButton = styled.button`
  padding: 1rem 1rem;
  font-size: 1rem;
  background: #7FFF00;
  color: black;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: transform 0.2s;
  font-weight: bold;

  &:hover {
    transform: scale(1.05);
  }
`;

const VideoModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const TeaserVideo = styled.video`
  width: 95vw;
  max-width: 1600px;
  height: auto;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
`;

export const VideoSection = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <VideoWrapper>
      <BackgroundImage />
      {showVideo ? (
        <VideoModal onClick={() => setShowVideo(false)}>
          <TeaserVideo controls autoPlay>
            <source src="/teaser.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </TeaserVideo>
        </VideoModal>
      ) : (
        <Content>
          <Title>
            <span>KOM I GANG</span>
            <span>MED FÅ TRIN</span>
          </Title>
          <PlayButton onClick={() => setShowVideo(true)}>
            Se intro video
          </PlayButton>
        </Content>
      )}
    </VideoWrapper>
  );
}; 