import { useState } from 'react';

export const useEmailSignup = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setStatus({ type: 'error', message: 'Indtast venligst en gyldig email' });
      return;
    }

    try {
      const response = await fetch('https://api.gopicksix.com/v1/subscriptions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      if (!response.ok) throw new Error();
      setStatus({ type: 'success', message: 'Tak for din tilmelding!' });
      setEmail('');
    } catch (error) {
      setStatus({ type: 'error', message: 'Der skete en fejl. Prøv igen senere.' });
    }
  };

  return {
    email,
    setEmail,
    status,
    handleSubmit
  };
}; 