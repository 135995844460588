import React from 'react';
import styled from 'styled-components';

// TODO: Handle image overlay not working
// TODO: Fix Logo placement
const FooterWrapper = styled.footer`
  background: #170422 url('/images/footer-mash.png') no-repeat center center;
  background-size: cover;
  padding: 3rem 2rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 300px repeat(3, 1fr);
  gap: 0rem;
  align-items: start;
  
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const Logo = styled.img`
  height: 45px;
  margin-top: 1.0rem;
  position: relative;
  left: 50px;
  
  @media (max-width: 1200px) {
    margin: 0 auto 1rem;
    left: 0;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: white;
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 0.4rem;
  }
  
  a {
    color: #ffffff;
    text-decoration: none;
    opacity: 0.8;
    font-size: 1.05rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    &:hover {
      opacity: 1;
    }
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Logo src="/images/logo-white.png" alt="Pick Six Logo" />
        
        <FooterSection>
          <h3>Det gode</h3>
          <ul>
            <li><a href="#top">Til toppen</a></li>
            <li><a href="#manifest">Manifest</a></li>
            <li><a href="#partnership">Partnerskab</a></li>
          </ul>
        </FooterSection>
        
        <FooterSection>
          <h3>Det kedelige</h3>
          <ul>
            <li><a href="#terms">Betingelser</a></li>
            <li><a href="#rules">Regler</a></li>
            <li><a href="#contact">Kontakt</a></li>
          </ul>
        </FooterSection>
        
        <FooterSection>
          <h3>Det sjove</h3>
          <ul>
            <li><a href="https://x.com/gopicksix">X</a></li>
            <li><a href="https://www.instagram.com/gopicksix">Instagram</a></li>
            <li><a href="https://bsky.app/profile/gopicksix.bsky.social">Blue Sky</a></li>
          </ul>
        </FooterSection>
      </FooterContent>
    </FooterWrapper>
  );
}; 