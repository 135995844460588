import React from 'react';
import styled from 'styled-components';

const HeaderOuterWrapper = styled.header`
  width: 100%;
  z-index: 1000;
  position: relative;
`;

const HeaderInnerWrapper = styled.div`
  max-width: min(1220px, calc(100% - 220px));
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  @media (max-width: 1200px) {
    max-width: calc(100% - 40px);
    padding: 1rem 0;
  }
`;

const Logo = styled.img`
  height: 45px;
  
  @media (max-width: 1200px) {
    height: 32px;
  }
`;

export const Header = ({ onImageError, imageErrors }) => {
  return (
    <HeaderOuterWrapper>
      <HeaderInnerWrapper>
        <Logo 
          src="/images/logo.png" 
          alt="Pick Six Logo" 
          onError={() => onImageError('logo')}
          style={{ display: imageErrors?.logo ? 'none' : 'block' }}
        />
      </HeaderInnerWrapper>
    </HeaderOuterWrapper>
  );
}; 