import React from 'react';
import styled from 'styled-components';

const BoxesContainer = styled.section`
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    row-gap: 30px;
    padding: 20px;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  max-width: 1128px;
  height: 280px;
  display: flex;
  gap: 32px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    gap: 24px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
`;

const TextContent = styled.div`
  width: 48%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 0 26px;
    padding-bottom: 32px;
  }

  /* Target the inner div holding title & description */
  > div {
    width: 100%;
    max-width: 424px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;

    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      padding: 0;
    }
  }
`;

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-weight: 900;
  font-style: italic;
  color: rgba(23, 4, 34, 1);
  margin: 0 0 16px 0;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 32px;
    width: 100%;
    word-wrap: break-word;
  }
`;

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(23, 4, 34, 0.8);
  margin: 0;

  @media (max-width: 768px) {
    width: 100%;
    word-wrap: break-word;
  }
`;

const ImageContainer = styled.div`
  width: 48%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    width: 100%;
    max-width: 552px;
    height: auto;
    border-radius: 12px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }
`;

export const ContentBoxes = () => {
  const boxes = [
    {
      title: "Vælg dine 6 startere",
      description: "Pick Six er det moderne managerspil, der giver alle fodboldfans muligheden for at demonstrere deres fodboldmæssige klogskab.",
      image: "/images/box1.png"
    },
    {
      title: "Vælg din sponsor og form din sejr",
      description: "Dit valg af sponsor åbner dørene til unikke præmier, rabatter og eksklusive deals, som er tilgængelige, uanset hvordan dit hold præsterer.",
      image: "/images/box2.png"
    },
    {
      title: "Dyst på den lange - eller korte bane",
      description: "Vælg de bedste unge spillere, de største stjerner fra sidste transfervindue, eller de skarpeste angribere. Pick Six er både varieret og spændende – igennem en hel sæson!",
      image: "/images/box3.png"
    },
    {
      title: "Følg udviklingen og stryg til tops",
      description: "Overalt - på arbejdspladser, i skoler, sportsklubber og især på sociale medier - finder du de passionerede \"Mandagstrænere\", som altid har stærke meninger om, hvordan en fodboldklub bør styres.",
      image: "/images/box4.png"
    }
  ];

  return (
    <BoxesContainer>
      {boxes.map((box, index) => (
        <ContentBox key={index}>
          <TextContent>
            <div>
              <Title>{box.title}</Title>
              <Description>{box.description}</Description>
            </div>
          </TextContent>
          <ImageContainer>
            <img src={box.image} alt={box.title} loading="lazy" />
          </ImageContainer>
        </ContentBox>
      ))}
    </BoxesContainer>
  );
};