import React from 'react';
import styled from 'styled-components';
import { useCookieConsent } from '../hooks/useCookieConsent';
import { useImageError } from '../hooks/useImageError';
import { ContentBoxes } from './ContentBoxes/ContentBoxes';
import { CookieConsent } from './CookieConsent/CookieConsent';
import { CountdownTimer } from './CountdownSection/CountdownSection';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { Hero } from './Hero/Hero';
import { VideoSection } from './VideoSection/VideoSection';

const PageWrapper = styled.div`
  min-height: 100vh;
  background: rgba(247, 244, 251, 1);
  overflow-x: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: ${props => {
    switch (props.$type) {
      case 'hero': return '-60px';
      case 'content': return '60px';
      case 'video': return '40px';
      case 'countdown': return '0px';
      default: return '120px';
    }
  }};

  @media (max-width: 1200px) {
    ${props => props.$type === 'countdown' && `
      margin-bottom: 85px;
    `}
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

function MainPage() {
  const { showCookieConsent, handleAcceptCookies } = useCookieConsent();
  const { imageErrors, handleImageError } = useImageError();

  return (
    <PageWrapper>
      <Header onImageError={handleImageError} imageErrors={imageErrors} />
      <Section $type="hero">
        <Hero onImageError={handleImageError} imageErrors={imageErrors} />
      </Section>
      <Section $type="content">
        <ContentBoxes />
      </Section>
      <Section $type="video">
        <VideoSection />
      </Section>
      <Section $type="countdown">
        <CountdownTimer />
      </Section>
      <Footer />
      {showCookieConsent && (
        <CookieConsent onAccept={handleAcceptCookies} />
      )}
    </PageWrapper>
  );
}

export default MainPage;